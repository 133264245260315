import { create } from 'zustand';

import supabaseClient from '../../../services/supabase/supabase.service';
import { IAuthStore } from './authStore.config';

export const useAuthStore = create<IAuthStore>()(set => ({
  user: null,
  accessToken: null,
  loading: false,
  signInWithOtp: async (params) => {
    set({ loading: true });
    return supabaseClient.auth.signInWithOtp(params).finally(() =>  set({ loading: false }));
  },
  verifyOTPLogin: async (params) => {
    set({ loading: true });
    return supabaseClient.auth.verifyOtp(params).finally(() =>  set({ loading: false }));
  },
  signInWithDiscord: async () => {
    set({ loading: true });
    return supabaseClient.auth.signInWithOAuth({
      provider: "discord",
      options: {
        redirectTo: location.origin,
      },
    }).finally(() =>  set({ loading: false }));
  },
  signOut: () => supabaseClient.auth.signOut(),
  setUser: user => {
    set(_state => ({
      user,
    }));
  },
  setAccessToken: accessToken => {
    set(_state => ({
      accessToken,
    }));
  },
}));
