import { Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  backgroundColor: '#000',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
});

const LoadingScreen = () => {
  return (
    <StyledContainer>
      <img className="animate-ping" src="/img/envoy-white.png" alt="ENVOY Portal Logo" />
    </StyledContainer>
  );
};

export default LoadingScreen;
