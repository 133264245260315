import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import { ApplicationMode } from '../shared/types';
import { useApplicationMode } from '../shared/hooks';
import LoadingScreen from '../screens/Loading.screen';

const Home = loadable(() => import('../screens/Home/Home.screen'), { fallback: <LoadingScreen /> });
const Bridge = loadable(() => import('../screens/Bridge/Bridge.screen'), { fallback: <LoadingScreen /> });
const Connect = loadable(() => import('../screens/Connect/Connect.screen'), { fallback: <LoadingScreen /> });
const Accounts = loadable(() => import('../screens/Accounts/Accounts.screen'), { fallback: <LoadingScreen /> });
const Profile = loadable(() => import('../screens/Profile.screen'), { fallback: <LoadingScreen /> });
const KYC = loadable(() => import('../screens/KYC.screen'));
const Maintenance = loadable(() => import('../screens/Maintenance/Maintenance.screen'));
const AcceptTransactions = loadable(() => import('../screens/Transactions/AcceptTransactions.screen'));
const BridgeTransactions = loadable(() => import('../screens/Transactions/BridgeTransactions.screen'));
const NotFound = loadable(() => import('../screens/NotFound.screen'));
const AuthRoute = loadable(() => import('./AuthRoute'));
const Login = loadable(() => import('../screens/Auth/Login.screen'));
const VerifyOtp = loadable(() => import('../screens/Auth/VerifyOtp.screen'))

const Navigation = function () {
  const mode = useApplicationMode();

  if (mode === ApplicationMode.Maintenance) {
    return <Maintenance />;
  }

  

  return (
    <Routes>
      <Route index element={<Home />} />

      <Route path="bridge" element={<Bridge />} />
      <Route path="bridge/transaction" element={<BridgeTransactions />} />

      <Route path="accept" element={<Connect />} />
      <Route path="accept/transactions" element={<AcceptTransactions />} />

      <Route path="accounts" element={<Accounts />} />

      <Route path="profile" element={<Profile />} />
      <Route path="kyc" element={<KYC />} />

      <Route path="auth/login" element={<AuthRoute element={<Login />} />} />
      <Route path="auth/verify-otp" element={<AuthRoute element={<VerifyOtp />} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Navigation;
