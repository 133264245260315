import { createContext, useState, useContext, ReactNode, FunctionComponent } from 'react';

interface DialogContextProps {
  open: boolean;
  title?: string;
  message: string;
  confirmButtonText: string;
  confirm: (() => void) | (() => Promise<void>);
  cancel: () => void;
  show: (message: string, onConfirm: (() => void) | (() => Promise<void>), confirmText?: string, title?: string) => void;
  isLoading: boolean;
}

const DialogContext = createContext<DialogContextProps | null>(null);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export const DialogProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState<string | undefined>();
  const [onConfirm, setOnConfirm] = useState<(() => void) | (() => Promise<void>)>(() => {});
  const [confirmButtonText, setConfirmButtonText] = useState('Confirm');
  const [isLoading, setIsLoading] = useState(false);

  const show = (message: string, confirmHandler: (() => void) | (() => Promise<void>), confirmText?: string, title?: string) => {
    setMessage(message);
    setTitle(title);
    setOnConfirm(() => confirmHandler);
    confirmText ? setConfirmButtonText(confirmText) : setConfirmButtonText('Confirm');
    setOpen(true);
  };

  const confirm = async () => {
    const result = onConfirm();

    if (result instanceof Promise) {
      setIsLoading(true);
      await result;
      setIsLoading(false);
    }

    setOpen(false);
  };

  const cancel = () => {
    setOpen(false);
  };

  return (
    <DialogContext.Provider value={{ open, message, title, confirmButtonText, isLoading, confirm, cancel, show }}>
      {children}
    </DialogContext.Provider>
  );
};
