import moment from 'moment';

export const TimeFormat = 'HH:mm:ss';

/**
 * @param startTime time string format HH:mm:ss
 * @param endTime time string format HH:mm:ss
 * @param currentTime time string format HH:mm:ss
 * @throws Error unvalid time string
 */
export const isTimeBetween = (
  startTime: string,
  endTime: string,
  currentTime?: string,
): boolean => {
  const now = currentTime
    ? moment.utc(currentTime, TimeFormat, true)
    : moment.utc();
  const startDate = moment.utc(startTime, TimeFormat, true);
  const endDate = moment.utc(endTime, TimeFormat, true);

  if (!now.isValid()) {
    throw new Error('Unvalid currentTime');
  }
  if (!startDate.isValid()) {
    throw new Error('Unvalid startTime');
  }
  if (!endDate.isValid()) {
    throw new Error('Unvalid endTime');
  }

  return now.isBetween(startDate, endDate);
};
