import { SupabaseClient, createClient } from '@supabase/supabase-js';

class SupabaseService {
  static INSTANCE: SupabaseService;
  supabaseClient: SupabaseClient;

  static getInstance = () => {
    if (!SupabaseService.INSTANCE) {
      SupabaseService.INSTANCE = new SupabaseService();
    }
    return SupabaseService.INSTANCE;
  };

  constructor() {
    const SUPABASE_URL = import.meta.env.VITE_APP_SUPABASE_URL;
    const SUPBASE_ANON_KEY = import.meta.env.VITE_APP_SUPABASE_ANON_KEY;

    this.supabaseClient = createClient(
      SUPABASE_URL as string,
      SUPBASE_ANON_KEY as string,
    );
  }
}

export const { supabaseClient } = SupabaseService.getInstance();
export default supabaseClient;
