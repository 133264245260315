import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    purple: {
      main: string;
    };
    green?: Palette['primary'];
    pink?: Palette['primary'];
    yellow?: Palette['primary'];
  }
  interface PaletteOptions {
    purple?: {
      main?: string;
    };
    neutral?: {
      main?: string;
    };
    neutralTransparent?: {
      main?: string;
    }
    green?: PaletteOptions['primary'];
    pink?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    green: true;
    pink: true;
    yellow: true;
  }
}

export const colors = {
  black: '#121212',
  white: '#FFF',
  purple: '#BE94FF',
  whiteTransparent: 'rgba(255, 255, 255, 0.7)',
  neutralTransparent: 'rgba(231,231,231, 0.4)',
  darkPurple: '#9147FF',
  neutral: '#12082B',
  indigo: '#3d187c',
};

export const fonts = {};

const iPhoneInput = {
  styleOverrides: {
    root: {
      '*': {
        WebkitUserSelect: 'text !important' /* Chrome, Opera, Safari */,
        MozUserSelect: 'text !important' /* Firefox 2+ */,
        msUserSelect: 'text !important' /* IE 10+ */,
        userSelect: 'text !important' /* Standard syntax */,
      },
    },
  },
};

export const theme = createTheme({
  components: {
    MuiTextField: iPhoneInput,
    MuiInput: iPhoneInput,
    MuiFilledInput: iPhoneInput,
    MuiOutlinedInput: iPhoneInput,
  },
  palette: {
    primary: {
      main: '#2196F3',
    },
    purple: {
      main: '#BE94FF',
    },
    neutral: {
      main: '#18181C',
    },
    neutralTransparent: {
      main: 'rgba(231,231,231, 0.1)',
    },
    secondary: {
      main: '#9147FF',
    },
    green: {
      main: '#22c55e',
      contrastText: '#000',
    },
    pink: {
      main: '#dc2626',
      contrastText: '#000',
    },
    yellow: {
      main: '#F19C1D',
    },
    info: {
      main: '#121212',
    },
    mode: 'dark',
  },
});
