import { useEffect } from 'react';

import '@fontsource/vt323';
import supabaseClient from '../core/services/supabase/supabase.service';
import { useAuthStore } from '../core/zustand/store/authStore/authStore';
import { API } from '../shared/api';
import { IApp } from './App.config';
import './App.css';
import axios from 'axios';
import ErrorBoundary from 'src/components/ErrorBoundary';

const App = function ({ children }: IApp) {

  const { user, setUser, setAccessToken } =
    useAuthStore();

  useEffect(() => {
    const fetchSession = async () => {
      const { data } = await supabaseClient.auth.getSession();
      if (data.session) {
        setUser(data.session.user);
        setAccessToken(data.session.access_token);
      }
    };
    fetchSession();
  }, [])

  useEffect(() => {

    const { data: listener } = supabaseClient.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
        setAccessToken(session?.access_token ?? null);

        if (event === 'SIGNED_IN') {
          axios.post(API.USER_PROFILE, {}, {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
              'Content-Type': 'application/json',
            }
          });
        };
      },
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, [user]);

  return (
    <ErrorBoundary>
      { children }
    </ErrorBoundary>
  );
};

export default App;
