import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDialog } from '../../core/lib/dialogContext';

export const ConfirmDialog = () => {
  const { open, message, title, confirmButtonText, isLoading, confirm, cancel } = useDialog();

  return (
    <Dialog 
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(32, 32, 32, 0.7)',
          paddingBottom: '12px',
        }
      }}
    >
      {title && (
        <>
        <DialogTitle
          textAlign="center"
        >
          {title}
        </DialogTitle>
        <Divider variant="middle" />
        </>
      )}
      <DialogContent 
        sx={{
          borderRadius: '12px'
        }}
      >
        {message}
      </DialogContent>
      <DialogActions 
        sx={{
          justifyContent: 'center'
        }}
      >
        <Button 
          onClick={cancel}
          sx={{
            borderRadius: '50px',
            backgroundColor: 'rgba(231, 231, 231, 0.1)',
            border: 'none',
            paddingX: 2,
            color: 'whitesmoke',
          }}
        >
          Cancel
        </Button>
        <LoadingButton 
          loading={isLoading}
          disabled={isLoading}
          onClick={confirm}
          color="primary"
          variant="contained"
          sx={{
            borderRadius: '50px',
            backgroundColor: '#2196F3',
            '&:hover': {
              backgroundColor: '#1976D2'
            }
          }}
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
